import styled from "styled-components";
import { Typography } from "@mui/material";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
type FleetDetails={
    icon:any;
    title:string;
}
const fleet:FleetDetails[]=[
{
    icon:<LocalShippingIcon style={{fontSize:"2.5rem"}} color="primary"/>,
    title:"40' Flatbeds",

},
{
    icon: <LocalShippingIcon style={{fontSize:"2.5rem"}} color="primary"/>,
    title:"40' Reefers",
},
{
    icon:<LocalShippingIcon style={{fontSize:"2.5rem"}}  color="primary"/>,
    title:"40' Side Curtain",
},
]
export default function Fleet() {

    return(
        <MainContainer>
            <Typography variant="h4" style={{color:"white",textTransform:"uppercase",fontWeight:"900"}}>choose your fleet</Typography>
            <FleetsContainer>
                {fleet.map((card)=>{
                   return <Card key={card.title} cardDetails={card}/>
                })}
            </FleetsContainer>
        </MainContainer>
    );
}

function Card({cardDetails}:{cardDetails:FleetDetails}) {
    return(
        <FleetCard>
            <IconCircle>
            {cardDetails.icon}
            </IconCircle>
            <Typography variant="h6" style={{color:"white",fontWeight:"bold",textAlign:"center"}}>{cardDetails.title}</Typography>
        </FleetCard>
    )
}
const MainContainer=styled.div`
background-color:${(props)=>props.theme.blue};
width:100%;
height:100%;
display:flex;
flex-direction:column;
justify-content:flex-start;
align-items:center;
padding: 2rem 0 2rem 0;
gap:2rem;
`;
const FleetsContainer=styled.div`
display:grid;
grid-template-columns:1fr 1fr 1fr;
grid-template-rows:1fr;
padding: 0 10% 0 10%;
width:80%;
justify-content:space-between;
align-items:center;
@media (max-width: 600px) {
    // display:flex;
    // flex-wrap:wrap;
    // justify-content:center;
    // align-items:center;
    // gap:2rem;
    width:100%;
    padding: 0 5% 0 5%;
}
`;
const FleetCard=styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
gap:0.5rem;
`;

const IconCircle=styled.div`
background-color:${(props)=>props.theme.background};
width:4rem;
height:4rem;
border-radius:50%;
display:flex;
justify-content:center;
align-items:center;
`;