
import { IntrestForm } from "../../pages/Interest/Interest";
import { firestore } from "../Firebase";
import {
  collection,
  getDocs,
  addDoc,
  Timestamp,
  updateDoc,
  doc,
  deleteField,
  getDoc,
  DocumentReference,
  arrayUnion,
  serverTimestamp,
  GeoPoint
} from "firebase/firestore";

export type firebaseResponse = {
    success: boolean;
    error?: string;
    };

export async function createLead(intrestDetails:IntrestForm) : Promise<firebaseResponse>{
    return addDoc(collection(firestore,"Leads"),{
        ...intrestDetails,
        createdAt:serverTimestamp()
    }).then((doc)=>{
        return {success:true as const,doc:doc};
    }).catch((err)=>{
        return {success:false as const,error:err.message};
    });
}