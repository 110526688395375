import React from 'react';
import logo from './logo.svg';
import { BrowserRouter as Router,Route,Routes }from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { createTheme, ThemeProvider as MUIThemeProvider} from '@mui/material/styles';

import Home from './pages/Home/Home';
import Intrest from './pages/Interest/Interest';

const theme={
  blue:'#1C2A57',
  yellow:'#FFDE00',
  background:'#EBF0F2',
  grey:'#5D5D5D'
}
let MUITheme=createTheme({
  palette: {
    primary:{
      main:"#1C2A57"
    },
    secondary:{
      main:"#FFDE00"
    },
  },
});
MUITheme=createTheme(MUITheme,{
  palette: {
    gray:{
      main:"#5D5D5D"
    },
    background:{
      main:"#EBF0F2"
    },
  },
})
MUITheme.typography.h2={
  ...MUITheme.typography.h2,
[MUITheme.breakpoints.down('sm')]:{
  // fontSize:"2.5rem",
  fontSize:"clamp(1.5rem, 10vw, 2rem)"
}
}
MUITheme.typography.h4={
  ...MUITheme.typography.h4,
  fontSize:"clamp(1.8rem, 3vw, 2.125rem)",
  [MUITheme.breakpoints.down('md')]:{
    fontSize:"clamp(1rem, 3vw, 1.8rem)",
  },

}
MUITheme.typography.h5={  
  ...MUITheme.typography.h5,
  [MUITheme.breakpoints.down('md')]:{
    textAlign:"center"
  },
  [MUITheme.breakpoints.down('sm')]:{
    fontSize:"1rem",
  }

}
MUITheme.typography.h6={
  ...MUITheme.typography.h6,
  [MUITheme.breakpoints.down('md')]:{
    fontSize:"1rem",
  },
}
MUITheme.typography.body1={
  ...MUITheme.typography.body1,
  fontSize:"clamp(0.8rem, 1vw, 1rem)",
}

function App() {
  return (
    <MUIThemeProvider theme={MUITheme}>
    <ThemeProvider theme={theme}>
    <Router>
      <Routes>
        <Route path="/"  element={<Home/>} />
        <Route path="/interest" element={<Intrest/>} />
      </Routes>
    </Router>
    </ThemeProvider>
    </MUIThemeProvider>
  );
}

export default App;
