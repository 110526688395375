import styled from "styled-components"
import Bahrain from "../../../assets/pictures/Bahrain-cropped-compressed.webp";
import { Typography } from "@mui/material";

export default function CTA() {
    return(
    <MainContainer>
        <ContentContainer>
        </ContentContainer>
        <Typography variant="h4" style={{backgroundColor:"#1C2A57",color:"white",padding:"1rem",textTransform:"uppercase",width:"fit-content",fontWeight:"bold",textAlign:"center"}}>future proofing your supply chain</Typography>
        <Typography variant="h4" style={{backgroundColor:"#1C2A57",color:"white",padding:"1rem",textTransform:"uppercase",width:"fit-content",fontWeight:"bold"}}>starts here</Typography>
    </MainContainer>
    )
}

const MainContainer=styled.div`
width:100%;
min-height:100vh;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
gap:1rem;
position:relative;
`
const ContentContainer=styled.div`
background-image:url(${Bahrain});
background-repeat:no-repeat;
background-size:cover;
filter: brightness(0.5);
z-index:-1;
position:absolute;
width:100%;
height:100%;
`