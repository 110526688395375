import React from 'react';
import styled from "styled-components"
import Bahrain from "../../assets/pictures/Bahrain-interest.jpg";
import * as yup from 'yup';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography,Button, CircularProgress, Alert } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import { useFormik } from "formik";
import CountryAutocomplete from "./components/CountryAutocomplete";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { createLead } from '../../Firebase/Firestore/Firestore';
const validationSchema = yup.object({
  companyType: yup.string().required('Company type is required'),
  companySize: yup.string().required('Comapny size is required'),
  companyName: yup.string().required('Company name is required'),
  contactName: yup.string().required('Contact name is required'),
  contactEmail: yup.string().email('Invalid email address').required('Email is required'),
  country: yup.object().required('Country is required'),
  contactNumber: yup.string().matches(/^[0-9]*$/, {message: "Invalid phone number.", excludeEmptyString: false}).min(7,"Invalid phone number").max(15,"Invalid phone number").required('Phone number is required')
});
const companyTypes=[
  "Manufacturer",
  "Retailer",
  "Transport Company",
  "Freight Forwarder",
  "Customs Clearing Agency",
  "Other"
];
const companySizes=[
  "0-50",
  "51-100",
  "101-150",
  "151-200",
  "200+"
]
export type IntrestForm={
  companyType:string;
  companyName:string;
  companySize:string;
  contactName:string;
  contactEmail:string;
  country:{ code: string; label: string; phone: string };
  contactNumber:string;
}
export default function Interest() {
const [loading,setLoading]=React.useState(false);
const [submissionAlert,setSubmissionAlert]=React.useState({open:false,success:false});
const formik=useFormik({
    initialValues:{
        companyType:"",
        companyName:"",
        companySize:"",
        contactName:"",
        contactEmail:"",
        country:{ code: 'BH', label: 'Bahrain', phone: '973' },
        contactNumber:"",
    },
    onSubmit:(values)=>{
      setLoading(true);
      setSubmissionAlert({open:false,success:false});
      createLead(values).then((res)=>{
        setSubmissionAlert({open:true,success:res.success});
        if(res.success){
          formik.resetForm();
        }
      }).catch((err)=>{
        setSubmissionAlert({open:true,success:false});
      }).finally(()=>{
        setLoading(false);
      })
    },
    validationSchema:validationSchema
});
  return(
    <MainContainer>

      <FormContainer>
      {
        submissionAlert.open && <CustomAlert alert={submissionAlert} setSubmissionAlert={setSubmissionAlert}/>
      }
        <Header>
          <Typography variant="h4" style={{textTransform:"uppercase",fontWeight:"900"}} color="primary">join us!</Typography>
          <Typography variant="body1" style={{color:"#5D5D5D" }}>Join us today and experience the future of road freight in the GCC.</Typography>
        </Header>



        <Form onSubmit={(e)=>{
          e.preventDefault();
          formik.handleSubmit();
        }}>
        <TextField fullWidth
        id="company-name"
        type='text'
        aria-label='company-name'
        value={formik.values.companyName}
        name="companyName"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.companyName && Boolean(formik.errors.companyName)}
        label="Company Name"
        helperText={formik.touched.companyName && formik.errors.companyName}
        FormHelperTextProps={{
          style:{
            position:"absolute",bottom:"-1.5rem",margin:"0"
          }
        }}
        />


          <div style={{display:"flex",width:"100%",gap:"0.5rem"}}>
        <FormControl fullWidth>
              <InputLabel id="company-type-label">Company Type</InputLabel>
              <Select
                labelId="company-type-label"
                id="company-type"
                value={formik.values.companyType}
                name="companyType"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.companyType && Boolean(formik.errors.companyType)}
                label="Company Type"
              >
                {companyTypes.map((companyType)=>{
                  return <MenuItem key={companyType} value={companyType}>{companyType}</MenuItem>
                })}
              </Select>
              <FormHelperText style={{color:"#d32f2f",position:"absolute",bottom:"-1.5rem",margin:0}}>{formik.touched.companyType && formik.errors.companyType}</FormHelperText>
        </FormControl>
        <FormControl fullWidth style={{position:"relative"}}>
              <InputLabel id="company-size-label">Company Size</InputLabel>
              <Select
                labelId="company-size-label"
                id="company-size"
                value={formik.values.companySize}
                name="companySize"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.companySize && Boolean(formik.errors.companySize)}
                label="Company Size"
              >
                {companySizes.map((size)=>{
                  return <MenuItem key={size} value={size}> {`${size} Employees`} </MenuItem>

                })}
              </Select>
              <FormHelperText style={{color:"#d32f2f",position:"absolute",bottom:"-1.5rem",margin:0}}>{formik.touched.companySize && formik.errors.companySize}</FormHelperText>
        </FormControl>
        </div>



        
        
        <div style={{display:"flex",width:"100%",gap:"0.5rem"}}>
         <TextField fullWidth
         id="contact-name"
         value={formik.values.contactName}
         name="contactName"
         onChange={formik.handleChange}
         onBlur={formik.handleBlur}
         error={formik.touched.contactName && Boolean(formik.errors.contactName)}
         label="Contact Name"
         helperText={formik.touched.contactName && formik.errors.contactName}
         FormHelperTextProps={{
           style:{
             position:"absolute",bottom:"-1.5rem",margin:0
           }
         }}
         />
         <TextField fullWidth
         id="contact-email"
         type='email'
         aria-label='contact-email'
         value={formik.values.contactEmail}
         name="contactEmail"
         onChange={formik.handleChange}
         onBlur={formik.handleBlur}
         error={formik.touched.contactEmail && Boolean(formik.errors.contactEmail)}
         label="Contact Email"
         helperText={formik.touched.contactEmail && formik.errors.contactEmail}
         FormHelperTextProps={{
           style:{
             position:"absolute",bottom:"-1.5rem",margin:0
           }
         }}
         />
        </div>
 <div style={{display:"flex",width:"100%",gap:"0.5rem"}}>
        <CountryAutocomplete formik={formik}/>
        <TextField fullWidth
        id="contact-number"
        inputMode="numeric"
        type='tel'
        value={formik.values.contactNumber}
        name="contactNumber"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
        label="Contact Number"
        helperText={formik.touched.contactNumber && formik.errors.contactNumber}
        FormHelperTextProps={{
          style:{
            position:"absolute",bottom:"-1.5rem",margin:0
          }
        }}
        InputProps={{
          startAdornment: (

            <InputAdornment position="start">
              {`+${formik.values.country.phone}`}
            </InputAdornment>

          ),
        }}

        />
        </div>

        <Button type='submit' variant='contained' fullWidth style={{marginTop:"2rem"}} disabled={loading}>{loading? <div style={{display:"flex",gap:"0.5rem",justifyContent:"center",alignItems:"center"}}>submit <CircularProgress size="1rem"/></div> : "submit "}</Button>
        </Form>
      </FormContainer>
      <Image src={Bahrain}/>
      <Home>
        <Button startIcon={<ArrowBackIosIcon color="primary"/>} variant="text" color="primary" style={{textTransform:"none"}} onClick={()=>{
          window.location.href="/"
        }}>Back Home</Button>
      </Home>
    </MainContainer>
  )
}

function CustomAlert({alert,setSubmissionAlert}:{alert:{open:boolean,success:boolean},setSubmissionAlert:Function}){
  return(
    <Alert severity={alert.success?"success":"error"} style={{position:"absolute",bottom:"0.2rem",zIndex:4}} onClose={()=>{
      setSubmissionAlert(false);
    }}>{alert.success?"Form submitted successfully!":"Error submitting form."}</Alert>
  )

}

const MainContainer=styled.div`
display: grid;
grid-template-columns: 1fr 1.5fr;
grid-template-rows: 1fr;
width: 100%;
height: 100vh;
@media (max-width: 1100px) {
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 810px) {
  grid-template-columns: 1fr;
}
`;
const FormContainer=styled.div`
position: relative;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
padding: 5rem 1rem 0 1rem;
box-shadow: 20px 1px 20px 20px rgb(0 0 0 / 38%);
z-index: 1;
@media (max-width: 810px) {
  padding: 5rem 1rem 0 1rem ;
  height: 100svh;
  box-shadow: none;
}
`;
const Image=styled.img`
width: 100%;
height: 100%;
object-fit: cover;
object-position: center;
filter: brightness(0.8);
@media (max-width: 810px) {
  display: none;
}
`;
const Header=styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width: 70%;
margin-bottom: 2rem;
gap: 1rem;
@media (max-width: 810px) {
  width: 100%;
}
`;
const Form=styled.form`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width: 70%;
gap: 1.75rem;
@media (max-width: 810px) {
  width: 100%;
}
`;
const Home=styled.div`
position: absolute;
top: 1rem;
left: 1rem;
display: flex;
justify-content: flex-end;
align-items: felx-end;
cursor: pointer;
z-index: 2;
`;
