import styled from "styled-components";
import { Typography } from "@mui/material";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
type CardDetails={
    icon:any;
    title:string;
    desc:string;
}
const cards:CardDetails[]=[
{
    icon:<AllInclusiveIcon style={{fontSize:"4rem"}} color="primary"/>,
    title:"Capacity",
    desc:"Access to a platform that makes pricing and monitoring transport easy, with thousands of qualified carriers to help you avoid delays"
},
{
    icon: <GpsFixedIcon style={{fontSize:"4rem"}} color="primary"/>,
    title:"Control",
    desc:"A digital shipping marketplace and lightweight management system to get a single view of all their upcoming, ongoing, and completed shipments"
},
{
    icon:<SupportAgentIcon style={{fontSize:"4rem"}} color="primary"/>,
    title:"Support",
    desc:"With our GCC logistics expertise, we can help shippers and carriers alike succeed, we’re just a call or click away"
}
]
export default function ValueProp() {

    return(
        <MainContainer>
            <Typography variant="h4" style={{color:"black",textTransform:"uppercase",fontWeight:"900"}}>make trucking a haul lot easier</Typography>
            <Typography variant="body1" style={{color:"#5D5D5D",fontWeight:"bold" ,width:"50%",textAlign:"center"}}>Our technology platform helps you streamline your road trucking operations, get better rates, and spend less time on paperwork.</Typography>
            <ValuePropsContainer>
                {cards.map((card)=>{
                   return <Card key={card.title} cardDetails={card}/>
                })}
            </ValuePropsContainer>
        </MainContainer>
    );
}

function Card({cardDetails}:{cardDetails:CardDetails}) {
    return(
        <ValuePropCard>
            {cardDetails.icon}
            <Typography variant="h6" style={{fontWeight:"bold"}}>{cardDetails.title}</Typography>
            <Typography variant="body1" color="gray.main" style={{width:"60%",textAlign:"center"}}>{cardDetails.desc}</Typography>
        </ValuePropCard>
    )
}
const MainContainer=styled.div`
background-color:${(props)=>props.theme.background};
width:100%;
height:100%;
display:flex;
flex-direction:column;
justify-content:flex-start;
align-items:center;
padding: 2rem 0 2rem 0;
gap:2rem;
`;
const ValuePropsContainer=styled.div`
display:flex;
@media (max-width: 768px) {
    flex-direction:column;
    gap:1rem;
}
`;
const ValuePropCard=styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
gap:0.5rem;
`;