import styled from "styled-components";
import { Icon, Typography } from "@mui/material";
import InventoryIcon from '@mui/icons-material/Inventory';
import MapIcon from '@mui/icons-material/Map';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
type ServiceDetails={
    icon:any;
    title:string;
}
const cards:ServiceDetails[]=[
{
    icon:<LocalShippingIcon style={{fontSize:"2.5rem"}} color="primary"/>,
    title:"FTL",

},
{
    icon: <InventoryIcon style={{fontSize:"2.5rem",}} color="primary"/>,
    title:"LTL",
},
{
    icon:<MapIcon style={{fontSize:"2.5rem",}} color="primary"/>,
    title:"Cross Border",
},
{
    icon:<AccessTimeIcon style={{fontSize:"2.5rem"}} color="primary"/>,
    title:"Daily Rentals",
}
]
export default function Services() {

    return(
        <MainContainer>
            <Typography variant="h4" style={{color:"white",textTransform:"uppercase",fontWeight:"900"}}>own the roads</Typography>
            <ServicesContainer>
                {cards.map((card)=>{
                   return <Card key={card.title} cardDetails={card}/>
                })}
            </ServicesContainer>
        </MainContainer>
    );
}

function Card({cardDetails}:{cardDetails:ServiceDetails}) {
    return(
        <ServiceCard>
            <IconCircle>
            {cardDetails.icon}
            </IconCircle>
            <Typography variant="h6" style={{color:"white",fontWeight:"bold",textAlign:"center"}}>{cardDetails.title}</Typography>
        </ServiceCard>
    )
}
const MainContainer=styled.div`
background-color:${(props)=>props.theme.blue};
width:100%;
height:100%;
display:flex;
flex-direction:column;
justify-content:flex-start;
align-items:center;
padding: 2rem 0 2rem 0;
gap:2rem;
`;
const ServicesContainer=styled.div`
display:grid;
grid-template-columns:1fr 1fr 1fr 1fr;
grid-template-rows:1fr;
padding: 0 10% 0 10%;
width:80%;
justify-content:space-between;
align-items:center;
@media (max-width: 600px) {
    grid-template-columns:1fr 1fr;
    grid-template-rows:1fr 1fr;
    row-gap:2rem;
    column-gap:2rem;
    padding: 0 5% 0 5%;
}
`;
const ServiceCard=styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
gap:0.5rem;
`;
const IconCircle=styled.div`
background-color:${(props)=>props.theme.background};
width:4rem;
height:4rem;
border-radius:50%;
display:flex;
justify-content:center;
align-items:center;
`;