import styled from "styled-components";
import carrier from "../../../assets/pictures/carrier-cropped-compressed.webp";
import shipper from "../../../assets/pictures/shipper-cropped-compressed.webp";
import { Button, Typography } from "@mui/material";
export default function ShippersAndCarriers() {
    return(
        <MainContainer>
            <ShipperPictureContainer/>
            <ShipperTextContainer>
                <Typography variant="h6" textTransform="uppercase" color="white" fontWeight="600" style={{width:"fit-content"}}>shippers</Typography>
                <Typography variant="h4" color="white" fontWeight="600" style={{width:"fit-content"}}>Get access to our near unlimited capacity of trucks</Typography>
                <Typography variant="body1" color="white">Truckly offers unparalleled flexibility and uncompromising quality, delivering lower shipping costs.</Typography>
                <Button color="secondary" variant="contained" style={{width:"fit-content",fontWeight:"bold",color:"primary"}} disableElevation onClick={()=>{window.location.href="/interest"}}><Typography variant="body1" fontWeight="bold">save money now</Typography></Button>
            </ShipperTextContainer>
            <CarrierTextContainer>
            <Typography variant="h6" textTransform="uppercase" color="primary" fontWeight="600" style={{width:"fit-content"}}>carriers</Typography>
                <Typography variant="h4" color="black" fontWeight="600" style={{width:"fit-content"}}>Keep your trucks moving</Typography>
                <Typography variant="body1" color="black">Truckly puts you in the drivers seat propelling your business forward by keeping your trucks full.</Typography>
                <Button color="secondary" variant="contained" style={{width:"fit-content",fontWeight:"bold",color:"primary"}} disableElevation onClick={()=>{window.location.href="/interest"}}><Typography variant="body1" fontWeight="bold" whiteSpace="nowrap">earn money now</Typography></Button>
            </CarrierTextContainer>
            <CarrierPictureContainer/>

        </MainContainer>
    )
}
const MainContainer=styled.div`
display:grid;
grid-template-columns:1fr 1fr;
grid-template-rows:1fr 1fr;
min-height:100vh;
@media (max-width: 600px) {
    grid-template-columns:1fr;
    grid-template-rows:1fr 1fr 1fr 1fr;
}
`;

const ShipperPictureContainer=styled.div`
background-image:url(${shipper});
background-repeat:no-repeat;
background-size:cover;
background-position:top;
width:100%;
height:100%;
filter: brightness(0.9);

`;
const ShipperTextContainer=styled.div`
background-color:${(props)=>props.theme.blue};
width:100%;
height:100%;
display:flex;
flex-direction:column;
justify-content:center;
padding:2rem;
gap:1rem;
`;
const CarrierTextContainer=styled.div`
background-color:${(props)=>props.theme.background};
width:100%;
height:100%;
display:flex;
flex-direction:column;
justify-content:center;
padding:2rem;
gap:1rem;
@media (max-width: 600px) {
    grid-row:4;
}
`;
const CarrierPictureContainer=styled.div`
background-image:url(${carrier});
background-repeat:no-repeat;
background-size:cover;
background-position:center;
width:100%;
height:100%;
filter: brightness(0.9);

`;