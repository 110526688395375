import styled from "styled-components";
import { Typography } from "@mui/material";
import platform from "../../../assets/pictures/platform.jpg";
import ComputerIcon from '@mui/icons-material/Computer';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import MediationIcon from '@mui/icons-material/Mediation';
type FeatureDetails={
    icon:any;
    title:string;
}
type ServiceDetails={
    icon:any;
    title:string;
}
const features:ServiceDetails[]=[
{
    icon:<ComputerIcon style={{fontSize:"4rem"}} color="primary"/>,
    title:"Digital Booking",

},
{
    icon: <GpsFixedIcon style={{fontSize:"4rem"}} color="primary"/>,
    title:"Live Tracking",
},
{
    icon:<QueryStatsIcon style={{fontSize:"4rem"}} color="primary"/>,
    title:"Data Analytics",
},
{
    icon:<MediationIcon style={{fontSize:"4rem"}} color="primary"/>,
    title:"Seamless Integration",
}
]
export default function Platform() {
    return(
        <MainContainer>
            <PlatformPicture/>
            <ContentContainer>
                <Typography variant="h4" style={{textTransform:"uppercase",fontWeight:"900",width:"100%",textAlign:"center"}} color="primary">control your freight</Typography>
                <Typography variant="body1" style={{color:"#5D5D5D",fontWeight:"bold" ,width:"50%",textAlign:"center"}}>With Truckly, your freight is always at your fingertips. Just log in to our secure online platform to quote, book, track, and manage your shipments, 24/7</Typography>
                <FeaturesGrid>
                    {features.map((feature)=>{
                          return <Card key={feature.title} cardDetails={feature}/>
                    })}
                </FeaturesGrid>
            </ContentContainer>
        </MainContainer>
    )
}

function Card({cardDetails}:{cardDetails:FeatureDetails}) {
    return(
        <FeatureCard>
            {cardDetails.icon}
            <Typography variant="h6" style={{fontWeight:"bold",textAlign:"center"}}>{cardDetails.title}</Typography>
        </FeatureCard>
    )
}

const MainContainer=styled.div`
display:grid;
grid-template-columns:1fr 1fr;
grid-template-rows:1fr;
min-height:100vh;
@media (max-width: 768px) {
    grid-template-columns:1fr;
    grid-template-rows:1fr 1fr;
}
`;

const PlatformPicture=styled.div`
background-image:url(${platform});
background-repeat:no-repeat;
background-size:contain;
background-position:center;
width:100%;
height:100%;
`
const ContentContainer=styled.div`
background-color:white;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
padding:2rem 0 2rem 0;
gap:2rem;
`;

const FeaturesGrid=styled.div`
display:grid;
grid-template-columns:1fr 1fr;
grid-template-rows:1fr 1fr;
gap:2rem;
margin-top:3rem;
`;

const FeatureCard=styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
gap:0.5rem;
`;