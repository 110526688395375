import { Button, Typography } from "@mui/material";
import styled from "styled-components";
import backgroundVideoPlaceholder from "../../../assets/pictures/video-placeholder.jpg";
const backgroundVideoMP4 = require("../../../assets/videos/hero.mp4");
const backgroundVideoWEBM = require("../../../assets/videos/hero.webm");

export default function Hero() {
  return (
    <MainContainer>
      <div style={{ position: "absolute", top: "0", right: "0", display: "flex", justifyContent: "flex-end", padding: "1rem", boxSizing: "border-box", backgroundColor: "#0000008f", width: "100vw" }}>
        <Button
          variant="text"
          style={{ color: "white" }}
          size="large"
          onClick={() => {
            window.location.href = "https://shippers.truckly.app";
          }}
        >
          login
        </Button>
        <Button
          variant="text"
          style={{ color: "white" }}
          size="large"
          onClick={() => {
            window.location.href = "/interest";
          }}
        >
          sign up
        </Button>
      </div>
      <Video autoPlay muted loop id="myVideo">
        <source src={backgroundVideoMP4} type="video/mp4" />
        <source src={backgroundVideoWEBM} type="video/webm" />
      </Video>
      <ContentContainer>
        <Typography variant="h2" style={{ backgroundColor: "#1C2A57", color: "white", padding: "1rem", textTransform: "uppercase", width: "fit-content", whiteSpace: "nowrap", gridColumn: "1/ span 2" }}>
          need a <strong>truck?</strong>
        </Typography>
        <Typography variant="h2" style={{ backgroundColor: "#1C2A57", color: "white", padding: "1rem", textTransform: "uppercase", width: "fit-content", whiteSpace: "nowrap", gridColumn: "1/ span 2" }}>
          get a <strong>truck.</strong>
        </Typography>
        <Typography variant="h5" style={{ color: "white" }}>
          Move everything forward with our advanced digital freight platform for Shippers & Carriers
        </Typography>
      </ContentContainer>
    </MainContainer>
  );
}
const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  @media (max-width: 900px) {
    justify-content: center;
  }
`;
const Video = styled.video`
  height: 100lvh;
  width: 100%;
  object-fit: cover;
  filter: brightness(0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-image: url(${backgroundVideoPlaceholder});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #4b4244;
`;
const ContentContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 1rem;
  padding: 3rem;
  @media (max-width: 900px) {
    justify-items: center;
    grid-template-columns: 1fr;
  }
`;
