import CTA from "./sections/CTA";
import Fleet from "./sections/Fleet";
import Footer from "./sections/Footer";
import Hero from "./sections/Hero";
import Platform from "./sections/Platform";
import Services from "./sections/Services";
import ShippersAndCarriers from "./sections/ShippersAndCarriers";
import ValueProp from "./sections/ValueProp";

export default function Home() {
    return(
        <>
        <Hero/>
        <ValueProp/>
        <ShippersAndCarriers/>
        <Services/>
        <Platform/>
        <Fleet/>
        <CTA/>
        {/* <Footer/> */}
        </>
    )
}